import { connect } from 'react-redux';

import { selectIsLoggedIn, selectUserRestaurant, selectIsRestaurantFetchFailed } from 'src/modules/User';

import { emitMessage, dismissFirstMessage } from 'src/modules/Modal';

const mapDispatchToProps = {
    emitMessage,
    dismissFirstMessage
};

const mapStateToProps = (state) => ({
    isLoggedIn: selectIsLoggedIn(state),
    restaurant: selectUserRestaurant(state),
    isRestaurantFetchFailed: selectIsRestaurantFetchFailed(state)
});

export default connect(mapStateToProps, mapDispatchToProps);
