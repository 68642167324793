import { connect } from 'react-redux';
import {
    login,
    selectIsLoggingIn,
    selectIsLoggedIn,
    selectUserRestaurant,
    selectLoginError,
    selectPrefferedUserLoginName
} from 'src/modules/User';

import { emitMessage, dismissFirstMessage } from 'src/modules/Modal';

const mapDispatchToProps = {
    login,
    emitMessage,
    dismissFirstMessage
};

const mapStateToProps = (state) => ({
    isLoggingIn: selectIsLoggingIn(state),
    isLoggedIn: selectIsLoggedIn(state),
    loginError: selectLoginError(state),
    restaurant: selectUserRestaurant(state),
    loginName: selectPrefferedUserLoginName(state)
});

export default connect(mapStateToProps, mapDispatchToProps);
