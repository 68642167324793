import { useEffect, useCallback } from 'react';
import Router, { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { MESSAGE_TYPE } from 'src/modules/Modal';

import { selectUserRestaurantSlug } from 'src/modules/User';

import LoginForm from 'src/components/Login';
import PropTypes from 'prop-types';
import PanelLayout from 'src/layouts/PanelLayout';

const ApplicationRouter = ({
    isLoggedIn,
    emitMessage,
    dismissFirstMessage,
    restaurant,
    isRestaurantFetchFailed
}) => {
    const { query: { code } } = useRouter();
    const restaurantSlug = useSelector(selectUserRestaurantSlug);
    const intl = useIntl();

    const handleLogin = useCallback((slug) => {
        dismissFirstMessage();

        Router.push(`/restaurant/${slug}/tables`);
    });

    useEffect(() => {
        if (code && !isLoggedIn && restaurant?.routeType === 0) {
            emitMessage({
                title: intl.formatMessage({ id: 'App.enterTheCodeFromTheSentMessage' }),
                subtitle: intl.formatMessage({ id: 'App.login' }),
                message: (
                    <LoginForm
                      code={code}
                      onLogin={handleLogin}
                    />
                ),
                type: MESSAGE_TYPE.SMALL,
                isEscKeyDownEnabled: false,
                isDismissButtonEnabled: false,
                isBackdropClickEnabled: false,
                hasFixedContent: true
            });
        }
    }, [code, isLoggedIn, restaurant?.routeType]);

    useEffect(() => {
        // Waiter loggedin
        if (isLoggedIn && restaurant && restaurant.routeType === 0) {
            Router.push(`/restaurant/${restaurantSlug}/tables`);
        }

        // Active order preview - table code with active order
        if (isLoggedIn && restaurant && restaurant.routeType === 1) {
            Router.push(`/restaurant/${restaurantSlug}/tables/${restaurant.tableId}`);
        }

        if (!isLoggedIn && restaurant && restaurant.routeType === 1) {
            Router.push(`/logout`);
        }

        // Assign Code
        if (isLoggedIn && restaurant && restaurant.routeType === 2) {
            Router.push(`/restaurant/${restaurantSlug}/assign/${code}`);
        }

        // Invalid code
        if (isRestaurantFetchFailed && !restaurant) {
            Router.push(`/logout`);
        }
    }, [isLoggedIn, restaurantSlug, code, restaurant?.routeType, isRestaurantFetchFailed]);

    return (
        <PanelLayout
          isMenuEnabled={false}
          isHeaderVisible={false}
        />
    );
};

ApplicationRouter.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    emitMessage: PropTypes.func.isRequired,
    dismissFirstMessage: PropTypes.func.isRequired,
    restaurant: PropTypes.shape({
        routeType: PropTypes.number,
        tableId: PropTypes.string
    }),
    isRestaurantFetchFailed: PropTypes.bool
};

export default ApplicationRouter;
