import Head from 'next/head';
import ApplicationRouter from 'src/components/ApplicationRouter';

const CodePage = () => (
    <>
        <Head>
            <title>apetit.io</title>
            <link rel="icon" href="/favicon.ico" />
        </Head>
        <ApplicationRouter />
    </>
);

export default CodePage;
