import { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import DiContext from '@aveneo/frontmoon-di/lib';
import noop from 'lodash/noop';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { selectUserRestaurantSlug, selectRestaurantId } from 'src/modules/User';
import LoginCode from 'src/components/LoginCode';

import theme from 'src/styles/components/LoginForm.module.scss';

const LoginForm = ({
    login,
    code,
    isLoggingIn,
    isLoggedIn,
    onLogin,
    loginName,
    loginError,
    signInRestaurantId
}) => {
    const intl = useIntl();
    const context = useContext(DiContext);
    const { Input } = context.container;

    const restaurantSlug = useSelector(selectUserRestaurantSlug);
    const restaurantId = useSelector(selectRestaurantId);

    const handleLogin = useCallback((loginCode) => {
        login(loginCode, code, restaurantId);
    }, [code, signInRestaurantId]);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        return false;
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            onLogin(restaurantSlug);
        }
    }, [isLoggedIn]);

    return (
        <form
          className={theme.container}
          noValidate="novalidate"
          onSubmit={handleSubmit}
        >
            <div className={theme.content}>
                <Input
                  className={theme.code}
                  name="userDeviceCode"
                  readOnly
                  value={loginName}
                  disabled
                />
                {isLoggingIn && intl.formatMessage({ id: 'App.verification' })}
                <LoginCode onSubmit={handleLogin} />
                <div className={theme.invalidCode}>
                    {loginError && intl.formatMessage({ id: `Error.${loginError}` })}
                </div>
            </div>
        </form>
    );
};

LoginForm.propTypes = {
    login: PropTypes.func.isRequired,
    code: PropTypes.string.isRequired,
    isLoggingIn: PropTypes.bool.isRequired,
    formData: PropTypes.shape({
        values: PropTypes.shape({
            loginCode: PropTypes.string
        })
    }),
    isLoggedIn: PropTypes.bool.isRequired,
    loginError: PropTypes.string,
    onLogin: PropTypes.func,
    loginName: PropTypes.string,
    signInRestaurantId: PropTypes.string
};

LoginForm.defaultProps = {
    onLogin: noop
};

export default LoginForm;
